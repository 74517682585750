<template>
  <div class="projectWrapper">
    <router-view />
  </div>
</template>

<style >
* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #fffaed;
}
</style>
